/*
    CAUTION: PLEASE READ! This File was auto generated by the system.
    Any changes you make will be removed the next time it's generated.
*/
import { ItemService } from 'spark-core-dx/services'

export const Operations = {
  ApplicationItem : {
     AuthGuardInitialize: "AuthGuardInitialize",
     SelectDataApplications: "SelectDataApplications",
     SelectDataTestLarge: "SelectDataTestLarge",
     SelectDataTestMedium: "SelectDataTestMedium",
  },
  DeployItem : {
     Deploy: "Deploy",
  },
}

export class PortalAppConfigItemService extends ItemService {

    constructor(coreState, coreContext, isWarning) {
        super("Items/PortalAppConfig", coreState, coreContext, "APP", null, isWarning);
    }

}

export class AppItemService extends ItemService {

    constructor(coreState, coreContext, isWarning) {
        super("Items/App", coreState, coreContext, "APP", null, isWarning);
    }

}

export class DeployItemService extends ItemService {

    constructor(coreState, coreContext, isWarning) {
        super("Items/Deploy", coreState, coreContext, "APP", null, isWarning);
    }

}

export class EnvironmentItemService extends ItemService {

    constructor(coreState, coreContext, isWarning) {
        super("Items/Environment", coreState, coreContext, "APP", null, isWarning);
    }

}

export class PipelineTemplateItemService extends ItemService {

    constructor(coreState, coreContext, isWarning) {
        super("Items/PipelineTemplate", coreState, coreContext, "APP", null, isWarning);
    }

}

export class UITestItemService extends ItemService {

    constructor(coreState, coreContext, isWarning) {
        super("Items/UITest", coreState, coreContext, "APP", null, isWarning);
    }

}

