import React, { useState, useEffect } from 'react'
import './home.scss'

const Home = ({ }) => {
    return (
        <div className="home">
            <div className="home-landing">
                <h1>Welcome to CorePortal</h1>
            </div>
        </div>

    )

}

export default Home;



