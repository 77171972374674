import React, { useEffect, useState } from 'react';
import { FieldControl, ButtonControl } from 'spark-core-dx/components'
import { useGenericManager } from 'spark-core-dx/hooks'
import { SystemTypes } from 'spark-core-dx/services'
import { ItemServiceFactory } from '../../../services/ItemServiceFactory';
import { useCoreStateContext, useCoreContext } from 'spark-core-dx/contexts';
import _ from 'lodash'
import * as axios from 'axios';

import './DeployApp.scss'


const DeployApp = () => {
    const itemFactory = new ItemServiceFactory();
    const coreContext = useCoreContext();
    const coreState = useCoreStateContext();
    const deployService = itemFactory.GetItemService(itemFactory.ItemServiceEnum.Deploy, coreState, coreContext)
    const appService = itemFactory.GetItemService(itemFactory.ItemServiceEnum.Application, coreState, coreContext)

    const logMgr = useGenericManager(itemFactory.ItemServiceEnum.Log)
    const configMgr = useGenericManager(itemFactory.ItemServiceEnum.Config, itemFactory)


    const [deployForm, setDeployForm] = useState({
        AZSecret: "NS38Q~QQZjx1jSjc26deAcn-xi4uvw-sMpPsPb~o",
        PAT: "i26drayhh3ldm5zzdtwoltb4diebmu3hsb44ezkn6rmanmfkitiq",
        AppIdToDeploy: "266e8365-b25c-4592-a942-8cc1396ef51c",
        Env: "PROD"
    })
    const [config, setConfig] = useState(null)
    const [eventId, setEventId] = useState(null)
    const [allowRedeploy, setAllowRedeploy] = useState(false)

    const [logs, setLogs] = useState([])
    // const [renderedLogs, setRenderedLogs] = useState([])
    const [animationsIndex, setAnimationIndex] = useState(0)


    const grabConfig = async () => {
        let r = await configMgr.Query({ model: "x => x.RegisteredAppId == appId && x.Environment == env && x.IsActive == true && x.IsDeleted == false", params: { appId: deployForm.AppIdToDeploy, env: deployForm.Env } })
        if (r.Success) {
            setConfig(r.Items.first())
            return r.Items.first()
        }
    }

    useEffect(() => {
        if (!eventId)
            return

        const intervalId = setInterval(() => {
            logMgr.Query({ model: 'x => x.EventId == eventId && (x.MessageTypeId == 3051 || x.MessageTypeId == 1005 || x.MessageTypeId == 2007)', params: { eventId } }).then(r => {
                if (r?.Success) {
                    const newItems = r.Items.map(x => x.MessageDetails)
                    if (r.Items.some(x => x.MessageTypeId == 3051 || x.MessageTypeId == 2007)) {
                        handleClearInterval()
                        if (r.Items.some(x => x.MessageTypeId == 2007)) {
                            grabConfig()
                            setLogs([...newItems, "Please approve and deploy all pipelines, the system will verify the accessibility of the deployment"])
                        }
                    } else {
                        setLogs(prevLogs => {
                            const newLogs = newItems.filter(item => !prevLogs.includes(item))
                            if (prevLogs.length !== newLogs.length)
                                return [...prevLogs, ...newLogs]
                            else return []
                        })
                    }
                }

            })
        }, 5000);

        const handleClearInterval = () => {
            setAllowRedeploy(true)
            setEventId(null)
            clearInterval(intervalId)
        }
        return () => clearInterval(intervalId);
    }, [eventId, logs])

    useEffect(() => {
        let pingInterval;
        if (!config) {
            grabConfig()
            return
        } else if (config && logs.includes("Please approve and deploy all pipelines, the system will verify the accessibility of the deployment") && !logs.includes("Verifying Website and Azure Functions Deployment")) {
            setLogs(prevLogs => [...prevLogs, "Verifying Website and Azure Functions Deployment"])
            pingFunction()
            pingWebsite()
        }
    }, [config, logs])

    const handleOnChange = (value, fieldName) => {
        const copiedDeployedForm = { ...deployForm }
        copiedDeployedForm[fieldName] = value
        console.log(copiedDeployedForm, 'wiha?')
        setDeployForm(copiedDeployedForm)
    }

    const deploy = async (updatedConfig) => {
        const config = updatedConfig ?? await grabConfig()
        if (!config.DeploymentEventId) {
            await appService.getOperation('AuthGuardInitalize', deployForm.AppIdToDeploy, { env: deployForm.Env })
            await deployService.postOperation('Deploy', null, deployForm, null, null, true).then(r => {
                setEventId(r.first().CorrelationID)
            })
        } else {
            setEventId(config.DeploymentEventId)
        }
    }

    const pingFunction = async () => {
        console.log(config)
        axios.get(`https://${config.FuncAPIUri}/api/ping`).then(r => {
            setLogs(prevLogs => [...prevLogs, `Successfully accessed azure functions at https://${config.FuncAPIUri}/api/ping`])
        }).catch(e => {
            console.log("Function Errors:", e)
            setTimeout(pingFunction, 5000)
        })

    };

    const pingWebsite = async () => {
        //Look for a 200, CORS error is valid.
        axios.get(`https://${config.WebSiteUri}`).then(r => {
            setLogs(prevLogs => [...prevLogs, `Successfully accessed website url at https://${config.WebSiteUri}`])
        }).catch(e => {
            console.log("Weburil Errors;", e)
            setTimeout(pingWebsite, 5000)
        })
    };

    //Add rules around core portal deployment


    useEffect(() => {
        if (logs.length === 0)
            return

        const timer = setInterval(() => {
            setAnimationIndex((prevIndex) => prevIndex + 1);
        }, allowRedeploy ? 250 : 2500);

        if (animationsIndex === logs.length - 1) {
            clearInterval(timer)
        }
        return () => {
            clearInterval(timer);
        };
    }, [logs, animationsIndex])

    const redeploy = async () => {
        setLogs([])
        const newConfig = await grabConfig()
        const updatedConfig = { ...newConfig }
        updatedConfig.DeploymentEventId = null
        await configMgr._save(updatedConfig)
        deploy(updatedConfig);
    }

    return (
        <div className='deploy-container'>
            <div className="deploy">
                <FieldControl fieldMetadata={{
                    FieldName: "AppIdToDeploy",
                    IsRequired: true,
                    DisplayName: "Application",
                    FieldType: SystemTypes.UIFieldType.SelectList,
                    DataOperation: 'SelectDataApplications'
                }}
                    disableError={false}
                    value={deployForm["AppIdToDeploy"]}
                    onChange={handleOnChange}
                />
                <FieldControl fieldMetadata={{
                    FieldName: "Env",
                    IsRequired: true,
                    DisplayName: "Environment",
                    FieldType: SystemTypes.UIFieldType.SelectList,
                    DataJson: [{ value: 'PROD' }, { value: 'STAGE' }, { value: 'QA' }, { value: 'DEV' }]
                }}
                    disableError={false}
                    value={deployForm["Env"]}
                    onChange={handleOnChange}
                />
                <FieldControl fieldMetadata={{
                    FieldName: "AZSecret",
                    IsRequired: true,
                    DisplayName: "Azure Client Secret",
                    FieldType: SystemTypes.UIFieldType.TextBox,
                    MaxLength: 500
                }}
                    disableError={false}
                    value={deployForm["AZSecret"]}
                    onChange={handleOnChange}
                />
                <FieldControl fieldMetadata={{
                    FieldName: "PAT",
                    IsRequired: true,
                    DisplayName: "Azure Dev Ops PAT",
                    FieldType: SystemTypes.UIFieldType.TextBox,
                    MaxLength: 500
                }}
                    disableError={false}
                    value={deployForm["PAT"]}
                    onChange={handleOnChange}
                />
                {

                    allowRedeploy ? <ButtonControl onClick={redeploy}>Redeploy</ButtonControl> : <ButtonControl onClick={() => deploy()}>Deploy</ButtonControl>
                }
            </div>
            <div className="console-container">
                {
                    logs?.map((x, i) => {
                        return <div className="typewriter-container" key={i}>
                            <div className="typewriter-wrapper">
                                <div className="typewriter"
                                    style={{
                                        animation: i === animationsIndex ? `typing ${allowRedeploy ? .2 : 2}s steps(${x.length}) forwards, cursor .4s step-end infinite alternate` : '',
                                        borderRight: i === animationsIndex ? '1ch solid' : '',
                                        width: animationsIndex > i ? '100%' : '0'
                                    }}
                                >
                                    {x}
                                </div>
                            </div>
                        </div>
                    })
                }
            </div>
        </div>
    );
};

export default DeployApp;