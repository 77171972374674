import React from 'react';
import { Route } from 'react-router-dom';
import { SparkApp } from 'spark-core-dx';
import { DomainRoute } from 'spark-core-dx/domains';
import { useCoreRoute } from 'spark-core-dx/hooks';
import Home from './domains/home/screens/Home';
import HomeDomain from './domains/home/HomeDomain';
import CoreAuthDomain from './domains/coreAuth/CoreAuthDomain';
import Login from './domains/coreAuth/screens/Login';
import Setup from './domains/coreAuth/screens/Setup';
import { ItemServiceFactory as CurrentItemServiceFactory } from './services/ItemServiceFactory';
const App = () => {
  //	"CoreAuthKey": "03c00cef0ce7a23761c0b04f74f7d3ca51751fbc5e24ea432d02ea30e0b128ca",
  //  "CoreAuthSecret": "FDgUepqO6LfZrKmTzBtn0Dk0DcAlP19Xi1U4hz0poH73q8reCrPxtIyg9GFcETLq9sdzhhlI0aWmMo2dKOZU0g"
  const itemSvcFactory = new CurrentItemServiceFactory();
  const { getRoute } = useCoreRoute()
  console.log(getRoute('/coreauth/login/', 'CoreAuth'), '??')
  return (
    <SparkApp
      itemServiceFactory={itemSvcFactory}
      addonRoutes={[
        <Route key={'Public'} element={<DomainRoute domainName={'Public'} component={HomeDomain} />} >
          <Route exact path={'/'} element={<Home />} />
        </Route>,
        <Route key={'CoreAuth'} element={<DomainRoute domainName={'CoreAuth'} permissions={{ setup: ["InitalizeCorePortal"] }} component={CoreAuthDomain} />} >
          <Route path={getRoute('/coreauth/login/', 'CoreAuth')} element={<Login />} />
          <Route exact path={getRoute('/coreauth/setup/', 'CoreAuth')} element={<Setup />} />
        </Route>
      ]} />
  )
}

export default App;
