import React, { useState, useEffect } from 'react'
import { ItemServiceFactory } from 'spark-core-dx/services';
import { useGenericManager, useDefaultObject } from 'spark-core-dx/hooks';
import { useCoreStateContext, useCoreContext } from 'spark-core-dx/contexts';

import { useNavigate } from 'react-router-dom'
import DeployApp from '../components/DeployApp';

const Setup = ({ }) => {
    const routingDomainMgr = useGenericManager(ItemServiceFactory.ItemServiceEnum.RoutingDomain);
    const transformFieldsToObject = useDefaultObject();
    const navigate = useNavigate();
    const coreState = useCoreStateContext();
    const coreContext = useCoreContext();

    return (
        <>
            <h1>Setup Workflow goes here</h1>
            <DeployApp />
        </>
    )

}

export default Setup;



