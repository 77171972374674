import React from 'react';
import { Outlet } from 'react-router-dom';
import { EmptyLayout } from 'spark-core-dx/components';
import { CoreAuthDomainContextProvider } from './CoreAuthDomainProvider';

//Loads screens/items dynamically.
//No custom, always 1 to 1.

const CoreAuthDomain = () => {
    return (
        <CoreAuthDomainContextProvider >
            <EmptyLayout>
                <Outlet />
            </EmptyLayout>
        </CoreAuthDomainContextProvider>
    );
};

export default CoreAuthDomain;